<template>
  <search></search>
</template>

<script>
import SearchHistory from "../Components/MembersEdit/SearchHistory.vue";
export default {
  name: "SearchHistory",
  components: {
    search: SearchHistory,
  },
  data() {
    return {
      data: [],
    };
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>
